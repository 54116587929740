/*
    Paraíso (dark)
    Created by Jan T. Sott (http://github.com/idleberg)
    Inspired by the art of Rubens LP (http://www.rubenslp.com.br)
*/

/* Paraíso Comment */
.hljs-comment,
.hljs-quote {
  color: #8d8687;
}

/* Paraíso Red */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-link,
.hljs-meta {
  color: #ef6155;
}

/* Paraíso Orange */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-deletion {
  color: #f99b15;
}

/* Paraíso Yellow */
.hljs-title,
.hljs-section,
.hljs-attribute {
  color: #fec418;
}

/* Paraíso Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: #48b685;
}

/* Paraíso Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: #815ba4;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #2f1e2e;
  color: #a39e9b;
  padding: 0.5em;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
