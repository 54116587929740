@font-face {
  font-family: 'ElenaWebBasic';
  src: url('./ElenaWebBasicLight/ElenaWebBasicLight.eot');
  src: url('./ElenaWebBasicLight/ElenaWebBasicLight.eot?#iefix') format('embedded-opentype'),
  url('./ElenaWebBasicLight/ElenaWebBasicLight.woff2') format('woff2'),
  url('./ElenaWebBasicLight/ElenaWebBasicLight.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ElenaWebBasic';
  src: url('./ElenaWebBasicLightItalic/ElenaWebBasicLightItalic.eot');
  src: url('./ElenaWebBasicLightItalic/ElenaWebBasicLightItalic.eot?#iefix') format('embedded-opentype'),
  url('./ElenaWebBasicLightItalic/ElenaWebBasicLightItalic.woff2') format('woff2'),
  url('./ElenaWebBasicLightItalic/ElenaWebBasicLightItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'ElenaWebBasic';
  src: url('./ElenaWebBasicMedium/ElenaWebBasicMedium.eot');
  src: url('./ElenaWebBasicMedium/ElenaWebBasicMedium.eot?#iefix') format('embedded-opentype'),
  url('./ElenaWebBasicMedium/ElenaWebBasicMedium.woff2') format('woff2'),
  url('./ElenaWebBasicMedium/ElenaWebBasicMedium.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ElenaWebBasic';
  src: url('./ElenaWebBasicMediumItalic/ElenaWebBasicMediumItalic.eot');
  src: url('./ElenaWebBasicMediumItalic/ElenaWebBasicMediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./ElenaWebBasicMediumItalic/ElenaWebBasicMediumItalic.woff2') format('woff2'),
  url('./ElenaWebBasicMediumItalic/ElenaWebBasicMediumItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}