@font-face {
  font-family: 'ElenaWebBasic';
  src: url(/static/media/ElenaWebBasicLight.5fabc9f1.eot);
  src: url(/static/media/ElenaWebBasicLight.5fabc9f1.eot?#iefix) format('embedded-opentype'),
  url(/static/media/ElenaWebBasicLight.0ada9c7d.woff2) format('woff2'),
  url(/static/media/ElenaWebBasicLight.d769386f.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ElenaWebBasic';
  src: url(/static/media/ElenaWebBasicLightItalic.d86e0bc1.eot);
  src: url(/static/media/ElenaWebBasicLightItalic.d86e0bc1.eot?#iefix) format('embedded-opentype'),
  url(/static/media/ElenaWebBasicLightItalic.2b564857.woff2) format('woff2'),
  url(/static/media/ElenaWebBasicLightItalic.0e5a8fdf.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'ElenaWebBasic';
  src: url(/static/media/ElenaWebBasicMedium.7777227b.eot);
  src: url(/static/media/ElenaWebBasicMedium.7777227b.eot?#iefix) format('embedded-opentype'),
  url(/static/media/ElenaWebBasicMedium.bbb40781.woff2) format('woff2'),
  url(/static/media/ElenaWebBasicMedium.769da6b2.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ElenaWebBasic';
  src: url(/static/media/ElenaWebBasicMediumItalic.d513afd7.eot);
  src: url(/static/media/ElenaWebBasicMediumItalic.d513afd7.eot?#iefix) format('embedded-opentype'),
  url(/static/media/ElenaWebBasicMediumItalic.797ed86f.woff2) format('woff2'),
  url(/static/media/ElenaWebBasicMediumItalic.8ef54b76.woff) format('woff');
  font-weight: 700;
  font-style: italic;
}
body {
  font-family: 'ElenaWebBasic', 'Merriweather', serif;
}

h1, h2, h3 {
  font-family: 'Lato', sans-serif;
  -webkit-font-variant-ligatures: no-common-ligatures;
          font-variant-ligatures: no-common-ligatures;
  -webkit-font-feature-settings: "liga" 0, "clig" 0;
  font-feature-settings: "liga" 0, "clig" 0, "liga", "clig off";
}

code {
  font-family: 'Source Code Pro', monospace;
  font-size: 1.25rem;
}

.content p, .content ul, .content ol {
  line-height: 1.45;
}

.noligs {
  -webkit-font-variant-ligatures: no-common-ligatures;
          font-variant-ligatures: no-common-ligatures;
  -webkit-font-feature-settings: "liga" 0, "clig" 0;
  font-feature-settings: "liga" 0, "clig" 0, "liga", "clig off";
}
/*
    Paraíso (dark)
    Created by Jan T. Sott (http://github.com/idleberg)
    Inspired by the art of Rubens LP (http://www.rubenslp.com.br)
*/

/* Paraíso Comment */
.hljs-comment,
.hljs-quote {
  color: #8d8687;
}

/* Paraíso Red */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-link,
.hljs-meta {
  color: #ef6155;
}

/* Paraíso Orange */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-deletion {
  color: #f99b15;
}

/* Paraíso Yellow */
.hljs-title,
.hljs-section,
.hljs-attribute {
  color: #fec418;
}

/* Paraíso Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: #48b685;
}

/* Paraíso Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: #815ba4;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #2f1e2e;
  color: #a39e9b;
  padding: 0.5em;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

